.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container,
.css-14vyde3-MuiCollapse-wrapperInner {
  height: 600px;
  width: 100vw;
  max-width: 767px;
  z-index: 1;
}

.maping {
  max-width: 100%;
  height: 400px;
  width: 100vw;
  margin: 0 auto 15px;
}

:root {
  --store-color: black;
  /* highlight and primary color */
  --highlight-color: pink;
  --main-color: pink;
}

.modifierLabel:before {
  border-color: var(--store-color) !important ;
}

.modifierLabel:after {
  background-color: var(--store-color) !important ;
}

.cartDiv {
  background-color: rgb(6, 249, 99);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  height: 25px;
  width: 25px;
  flex-shrink: 1;
  position: absolute;
  /* left: -10px; */
  right: 16px;
  top: 22px;
  border: 2px solid white;
}
.cartSpan {
  font-weight: 900;
  color: white;
}
.countrySelect .MuiInputBase-root.MuiSelect-outlined,
.countrySelect .MuiSelect-select.MuiSelect-outlined {
  min-width: 100% !important;
  padding: 10px 5px !important;
}
.countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  margin-left: -33px !important;
}
.Payment .countrySelect .MuiOutlinedInput-root {
  border-color: rgb(221, 221, 221) !important;
}

.showAddModal{
  max-height: auto;
  transition: max-height 0.15s ease-out;
  display: block
}

.hideAddModal{ 
  max-height: 0px ;
overflow: hidden;
  display: none;
  transition: max-height 0.15s ease-out;
}
