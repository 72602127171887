@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Light.eot');
    src: url('MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Light.woff2') format('woff2'),
        url('MyriadPro-Light.woff') format('woff'),
        url('MyriadPro-Light.svg#MyriadPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-It.eot');
    src: url('MyriadPro-It.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-It.woff2') format('woff2'),
        url('MyriadPro-It.woff') format('woff'),
        url('MyriadPro-It.svg#MyriadPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Semibold.eot');
    src: url('MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Semibold.woff2') format('woff2'),
        url('MyriadPro-Semibold.woff') format('woff'),
        url('MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Bold.eot');
    src: url('MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Bold.woff2') format('woff2'),
        url('MyriadPro-Bold.woff') format('woff'),
        url('MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Regular.eot');
    src: url('MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Regular.woff2') format('woff2'),
        url('MyriadPro-Regular.woff') format('woff'),
        url('MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

