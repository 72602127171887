.OtpVerifyMs .Payment .group label {
  position: relative;
  color: #8898aa;
  font-weight: 300;
  height: auto;
  line-height: 40px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.OtpVerifyMs .Payment .group label > span {
  width: 100%;
  color: #616161;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  font-size: 15px;
  line-height: normal;
}

.OtpVerifyMs .group label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

.OtpVerifyMs label > span {
  width: 120px;
  text-align: right;
  margin-right: 30px;
}

.OtpVerifyMs .field {
  background: transparent;
  /* font-weight: 300; */
  border: 0;
  outline: none;
  flex: 1;
  cursor: text;
  border-bottom: 1px solid #ddd;
  padding: 8px 0px;
  height: 40px;
  color: #333 !important;
  font-size: 13px !important;

  font-family: "Maven Pro", sans-serif !important;
}
/* .OtpVerifyMs input { padding:0;} */

.OtpVerifyMs .Payment .title {
  padding: 0 10px;
}

.rider-note-modal .title {
  font-size: 20px;
}

.OtpVerifyMs .field::-webkit-input-placeholder {
  color: #a5afb5;
}

.OtpVerifyMs .field::-moz-placeholder {
  color: #a5afb5;
}

.OtpVerifyMs button {
  float: left;
  display: block;
  background: #666ee8;
  color: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  line-height: 38px;
  outline: none;
}

.OtpVerifyMs button:focus {
  background: #555abf;
}

.OtpVerifyMs button:active {
  background: #43458b;
}

.OtpVerifyMs .outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
}

.OtpVerifyMs .success,
.OtpVerifyMs .error {
  display: none;
  font-size: 13px;
}

.OtpVerifyMs .success.visible,
.OtpVerifyMs .error.visible {
  display: inline;
}

.OtpVerifyMs .error {
  color: #e4584c;
}

.OtpVerifyMs .success {
  color: #666ee8;
}

.OtpVerifyMs .success .token {
  font-weight: 500;
  font-size: 13px;
}
#card-holder-name::placeholder {
  color: #a5afb5;
}
#card-holder-name {
  color: #333;
  font-family: "Maven Pro", sans-serif !important;
  font-size: 13px !important;
}
