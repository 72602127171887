
.slide-enter,
.slide-exit {
  transition: transform 500ms ease;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

/* .pageSlider-exit > .page {
  z-index: 1;
}

.pageSlider-enter > .page {
  z-index: 10;
}

.pageSlider-enter.left > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right > .page {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left > .page {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.right > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 800ms ease-in-out;
} */
