@charset "utf-8";
/* font-family: 'PT Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
@import url("bulma/css/bulma.css");
@import url("material-design-icons/iconfont/material-icons.css");
@import url("../fonts/stylesheet.css");

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ff7y78");
  src: url("fonts/icomoon.eot?ff7y78#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ff7y78") format("truetype"),
    url("fonts/icomoon.woff?ff7y78") format("woff"),
    url("fonts/icomoon.svg?ff7y78#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Component-1--7:before {
  content: "\e901";
  font-size: 14px;
}
/* .icon-Component-14--1:before {
  content: "\e902";
} */
.icon-Group-146:before {
  content: "\e903";
}
/* cross */
.icon-Group-147 .path1:before {
  content: "\e904";
}
/* cross */
.icon-Group-147 .path2:before {
  content: "\e905";
  margin-left: -1em;
}
/* circle */
.icon-ellipse_fill:before {
  content: "\e906";
}
/* circle outline */
.icon-ellipse {
  width: 15px;
  border-radius: 1000px;
  border: 1px solid;
  background-color: #fff;
  height: 15px;
}
.icon-calendar:before {
  content: "\e900";
}
.Delivery .group span.icon-calendar {
  color: #d51a66;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}
li {
  list-style: none;
}
body {
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Myriad Pro";
  background: #f5f6f7;
  overflow: hidden;
  /* max-height: calc(100vh - 58px); */
}
body.white {
  overflow: auto;
  /*height: 110vh;*/
  max-width: 767px;
  margin: 0 auto;
}
body {
  /* min-height: 107vh; */
  position: relative;
}
p {
  color: #858585;
  font-size: 14px;
  line-height: 1.5;
}
* {
  box-sizing: border-box;
}
/*common*/
.btn.blue-btn {
  background: #00022e;
  color: #fff;
  padding: 5px 35px;
}
input,
button,
select,
input:focus,
button:focus,
select:focus,
input:active,
button:active,
select:active,
input.form-control,
input.form-control:focus,
input.form-control:active,
textarea.form-control,
textarea.form-control:focus,
textarea.form-control:active {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  box-shadow: none;
}
::placeholder {
  color: #c4c4c4;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c4c4;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c4c4c4;
}
img {
  max-width: 100%;
}

.wrapper {
  max-width: 767px;
  margin: auto;
  background-color: #fff;
  position: relative;
}
.colorBg {
  background: #3e23a1;
  background: -moz-linear-gradient(top, #3e23a1 0%, #3e50a1 47%, #d31b67 100%);
  background: -webkit-linear-gradient(
    top,
    #3e23a1 0%,
    #3e50a1 47%,
    #d31b67 100%
  );
  background: linear-gradient(to bottom, #3e23a1 0%, #3e50a1 47%, #d31b67 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e23a1', endColorstr='#d31b67',GradientType=0 );
}

.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}

.sub-btn button {
  height: 56px;
  font-size: 20px;
  font-weight: bold;
  background-color: #3e38a1;
  /* border-color: #3e38a1; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}

.colorGreyBg {
  background: #f5f5f5;
}

.backlink figure {
  margin-top: 10px;
  max-width: 12px;
}

.login-section {
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 0;
  padding-bottom: 70px;
  background: inherit;
}
.login-section.OtpVerifyMs {
  padding-bottom: 0px;
}
.colorBg .login-section {
  min-height: 100vh;
}
.login-section h1 {
  font-size: 2rem;
  font-weight: bold;
}

.errorMsg {
  color: rgb(255 255 255);
  margin-top: 5px;
  padding: 4px 0px;
  background: rgb(216, 42, 63);
  border-radius: 5px;
}

.group {
  position: relative;
  margin-bottom: 70px;
  justify-content: center !important;
}
.group.pass-input {
  margin-bottom: 58px;
}

.OtpVerifyMs .group {
  margin-bottom: 25px;
}

.login-section form,
.user-section form {
  width: 100%;
  /* margin: 7%; */
}

.login-section .forgot-password {
  margin-top: 16px;
}

.login-section .forgot-password a {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}

.login-section .sub-btn button,
.user-section .sub-btn button {
  height: 56px;
  font-size: 20px;
  font-weight: bold;
  background-color: #3e38a1;
  border-color: #3e38a1;
  border-radius: 5px;
  color: #fff;
}

.login-section input {
  font-size: 20px;
  padding: 10px 12px 10px 12px;
  display: block;
  /* width: 300px; */
  width: 100%;
  border: none;
  border-bottom: 1px solid #3e38a1;
  background: inherit;
  color: #fff;
}

/* .login-section input:focus {
  outline: none;
} */

.login-section .logo-sec {
  max-width: 215px;
  margin: 100px 0px 75px 0px;
}

.orderSummary {
  max-height: calc(100vh - 160px);
  overflow: auto;
}
.css-10f3oyn-MuiFormControl-root label.MuiFormLabel-filled,
.css-10f3oyn-MuiFormControl-root label.Mui-focused {
  font-size: 14px !important;
  top: -10px !important;
}
label + .css-ghsjzk-MuiInputBase-root-MuiInput-root {
  margin-top: 0px !important;
}
/* LABEL ======================================= */
.login-section label {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 0px;
  /* top: -10px; */
  top: -2px;
  border: 0 !important;
  box-shadow: none;
  outline: 0;
  /* transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; */
}
.wrapper.colorGreyBg.slide-enter-done {
  max-height: 90vh;
}

/* .ios-device
  ~ .MuiModal-root.MuiDrawer-root.MuiDrawer-modal.css-1pjnniz-MuiModal-root-MuiDrawer-root
  .login-section
  label {
  top: 0;
} */
.login-section .mobileInput label {
  left: -60px;
}

/* active state */
/* .login-section input:focus ~ label,
.login-section input:not(:placeholder-shown) ~ label {
  top: -20px;
  font-size: 16px;
  color: #fff;
} */

/* BOTTOM BARS ================================= */
/* .bar {
  position: relative;
  display: block;
  width: 100%;
} */
/* .bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
} 
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

.OtpVerifyMs .bar:before,
.OtpVerifyMs .bar:after {
  background: #db397b;
}
*/

/* active state */
/* .login-section input:focus ~ .bar:before,
.login-section input:focus ~ .bar:after {
  width: 50%;
} */

/* HIGHLIGHTER ================================== */
/* .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
} */

/* active state */
/* .login-section input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.OtpVerifyMs input:focus ~ .highlight {
  -webkit-animation: inputBlackHighlighter 0.3s ease;
  -moz-animation: inputBlackHighlighter 0.3s ease;
  animation: inputBlackHighlighter 0.3s ease;
} */

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@-webkit-keyframes inputBlackHighlighter {
  from {
    background: #db397b;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputBlackHighlighter {
  from {
    background: #db397b;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputBlackHighlighter {
  from {
    background: #db397b;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}

.top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}

.sm-content {
  text-align: left;
  margin-bottom: 50px;
}

.sm-content-mb-0 {
  text-align: left;
}

.sm-content-mb-0 p {
  color: #343434;
}

.sm-content h2 {
  font-size: 28px;
  font-weight: bold;
}
.sm-content p {
  color: #333;
  font-size: 16px;
}
.email-what-img {
  margin-left: -10px;
}
.sm-text {
  color: #858585;
  font-size: 14px;
  font-weight: bold;
}
.OtpVerifyMs {
  justify-content: unset;
  align-items: unset;
}

.OtpVerifyMs label {
  color: #333;
  font-size: 20px;
  text-transform: uppercase;
}
.OtpVerifyMs input {
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  padding: 8px 5px;
}

/* .OtpVerifyMs input:focus ~ label,
.OtpVerifyMs input:not(:placeholder-shown) ~ label {
  top: -28px;
  color: #333;
  font-size: 20px;
} */

.OtpVerifyMs .sm-content figure {
  max-width: 95px;
}

.fbIcon,
.googleIcon {
  max-width: 37px;
}

.img-con {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.circle-img {
  padding: 0 0;
  align-self: center;
  min-width: 70px;
}
.disable .circle-img,
.disable .naming-sec {
  display: none;
}
.circle-img-lg {
  background: url(../Assets/images/image_circle.svg) no-repeat center center /
    100% auto;
  height: 105px;
  padding: 0;
  border-radius: 50%;
}
.circle-img-lg figure {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.naming-sec h3 {
  font-size: 25px;
  color: #3e3e3e;
}
.circle-img figure {
  border: 1px solid #bbb;
  border-radius: 50%;
  margin: 0;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}
.hotel_name {
  margin: 50px 0px;
}
.hotel_name.con_back {
  margin: 30px 0px 20px 0px;
}
.hotel_name .back_link_con {
  margin: 20px 0px 0px 0px;
}

.back_link_con figure {
  line-height: normal;
  display: flex;
  align-items: center;
}
.back_link_con .back_link {
  margin-left: 5px;
  text-align: left;
  font-weight: bold;
  color: #d51b67;
  padding-left: 5px;
  text-transform: none;
  padding-left: 5px;
  text-transform: uppercase;
}
.hotel_name .naming-sec p {
  color: #858585;
  font-weight: bold;
}
.hotel_name h4 {
  font-size: 22px;
  font-weight: bold;
}
header {
  display: flex;
  margin: 0;
  align-items: center;
}
header .naming-sec .title_text {
  padding-top: 0px;
  font-size: 16px;
  font-weight: 500;
}
header .naming-sec .powered_text {
  margin-top: 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: normal;
}
.powered_logo {
  width: 36px;
  display: inline-block;
  margin: 0 0 5px 5px;
}
.circle-img-lg {
  background: url(../Assets/images/image_circle.svg) no-repeat center center /
    100% auto;
  height: 88px;
  width: 88px;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}
header .circle-img-lg .logo {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
header .circle-img-lg .logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Delivery .group {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 10px 0;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  font-size: 14px;
}
.Delivery .group h1 {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 22px 10px 22px;
}
.Delivery .group span {
  margin: 0px 25px 10px;
  text-align: left;
  font-weight: bold;
  /* color: #d51b67; */
  font-size: 14px;
}
.group .selectInput {
  margin: 2px 20px 15px 20px;
}
.group .selectInput.twoInp {
  margin: 2px 20px 15px;
}

.css-122dlte-MuiFormControl-root .MuiInputBase-root input {
  font-size: 14px;
}
.selectInput.twoInp .MuiOutlinedInput-input {
  padding-right: 0;
  font-size: 14px;
}
.selectInput.twoInp .MuiInputAdornment-root {
  width: 23px;
  display: flex !important;

  height: 40px !important;
}
.selectInput.twoInp .MuiInputAdornment-root svg {
  min-width: 20px;
  min-height: 30px;
}
.css-1qrdq6l-MuiFormControl-root .MuiInputBase-root input {
  padding-right: 0 !important;
}

.user-section {
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  /* min-height: 100%; */
  /* padding-bottom: 80px; */
  background: inherit;
  height: calc(100vh - 190px);
  overflow: auto;
}

.user-section.Help {
  background: #fff;
  min-height: auto;
  /* padding-bottom: 28px; */
}

.user-section.Help .MuiListItemIcon-root {
  justify-content: end;
}

.user-section.Help h1.title {
  margin: 35px 0px 40px 0px;
  text-align: left;
}
.user-section.Help.orderScreen .discription {
  margin: 35px 0px 40px 0px;
  color: #000;
  text-align: left;
  font-size: 16px;
}

.user-section .TrackStatus,
.user-section .Payment {
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 26px;
}
.user-section .Payment {
  padding-bottom: 0px;
  width: 100%;
}
.sm-title {
  margin: 0;
  padding: 8px 20px;
  font-weight: bold;
  color: #5b5b5d;
}
.item-details .selectors h5.sm-title.pl-1 {
  padding-left: 0 !important;
}

.TrackStatus .sm-title,
.Payment .sm-title,
.cart-screen .sm-title {
  text-align: left;
  font-size: 16px;
  color: #444343;
  margin: 21px 0px 21px 0px;
}

.Payment .title {
  text-align: left;
  color: #444343;
  margin: 21px 0px 21px 0px;
  font-size: 18px;
}

.anchor_link {
  font-weight: 600;
  color: #d51b67;
  font-size: 16px;
}
.OrderDetail .anchor_link {
  font-size: 23px;
}
.user-section .bottom-sec .sm-title {
  font-weight: bold;
  font-size: 16px;
}
.css-u75is7 .MuiStepLabel-labelContainer .MuiStepLabel-label,
.css-16ubnlw-MuiStepLabel-labelContainer {
  font-size: 14px !important;
}

.fullWidth {
  width: 100vh;
}
.fullHeight {
  height: 100vh;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}

.Payment .group .selectInput {
  margin: 15px 20px 30px 20px;
}

.Payment .group {
  margin-bottom: 13px;
}

.Payment .group label {
  color: #616161 !important ;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700 !important;
  margin-left: 0 !important;
  /* margin-bottom: 0 !important; */
  line-height: 1.4375em !important;
}
.OtpVerifyMs .Payment .contact_details .group label {
  position: absolute !important;
}
.Payment .group.phoneNum label {
  left: 112px;
}

.Payment .group label.MuiFormLabel-filled,
.Payment .group label.Mui-focused {
  /* color: #999 !important; */
}
.Payment .group.d-flex {
  gap: 15px;
}
/*.Payment label + .css-ghsjzk-MuiInputBase-root-MuiInput-root {
  margin-top: -8px !important;
}*/
.highlight-text {
  font-size: 16px;
  color: #999;
  font-weight: 700;
}
.rider-note-modal {
  display: flex;
  flex-wrap: wrap;
  margin: 3px;

  padding-top: 20px;
}
.rider-note-modal-btm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: end;
  margin: 3px;
  height: 100%;
}
.rider-note-modal .cross-btn-con {
  margin: 0px 0px 0px 0px;
  padding-right: 18px;
  position: absolute;
  right: 0px;
  z-index: 9;
}
.css-1si59tq-MuiFormControl-root-MuiTextField-root label.MuiFormLabel-filled {
  top: -8px;
  font-size: 18px !important;
}
.rider-note-modal .sm-title {
  color: #000;
  font-size: 15px;
}
.rider-note-modal label {
  font-weight: bold;
}
.rider-note-modal .sm-content h2 {
  font-weight: bold;
  font-size: 18px;
}

.user-section .OrderDetail,
.user-section .OrderDetailMid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.user-section .OrderDetailMid {
  text-align: left;
}
.user-section .OrderDetailMid div,
.user-section .OrderSummary div {
  margin-top: 7px;
}
.img-mid {
  display: flex;
  justify-content: center;
}

.OrderDetail .sm-title {
  text-align: left;
  color: #000;
}
.OrderDetailMid .sm-title,
.OrderSummary .sm-title {
  color: #5b5b5b;
  font-weight: 600;
  padding: 0;
  font-size: 16px;
}

.user-section .OrderSummary {
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px 10px 60px;
  text-align: left;
}
.user-section .OrderSummary .tax-text {
  font-size: 20px;
  font-weight: normal;
}

.OrderDetailCon .bottom-sec .sm-title {
  margin-top: 27px;
  font-weight: normal;
  font-size: 14px;
  color: #323232;
}

/*home*/
.home-screen strong {
  color: #fff;
  font-weight: 600;
  text-transform: none;
  font-size: 18px;
  white-space: nowrap;
  text-transform: uppercase;
}
.home-screen .total-price strong {
  font-size: 20px;
  display: block;
  margin-bottom: -5px;
  flex: 0 0 100%;
}
.home-screen header {
  padding: 20px 0 0;
  overflow: hidden;
}
.powered_logo {
  width: 45px;
  display: inline-block;
}
.circle-img {
  padding: 0 0;
  align-self: center;
}
.disable .circle-img,
.disable .naming-sec {
  display: none;
}
.profile-row.disable {
  justify-content: flex-end;
}
.circle-img-lg {
  background: url(../Assets/images/image_circle.svg) no-repeat center center /
    100% auto;
  /* height: 105px; */
  padding: 0;
  border-radius: 50%;
}
.circle-img-lg figure {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.naming-sec h3 {
  font-size: 25px;
  color: #3e3e3e;
}
.circle-img figure {
  border: 1px solid #bbb;
  border-radius: 50%;
  margin: 0;
  height: 65px;
  width: 65px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}
.naming-sec h4 {
  font-weight: bold;
  font-size: 22px;
  color: #3d3e3d;
  margin: 0;
}

.home-screen header .container {
  padding: 0 15px;
}
.naming-sec p {
  margin: 0;
  color: #3d3e3d;
  font-size: 13px;
  margin-top: 0px;
}
.search-bar.input-text {
  background: #fff url(../Assets/images/search_icon.svg) no-repeat 15px 50% /
    18px auto;
  border: 1px solid #bababc;
  margin: 8px auto;
  border-radius: 5px;
  height: 45px;
  padding: 0 20px 0 45px;
  width: 100%;
  font-size: 16px;
  color: #3d3d3d;
}
.notification {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url(../Assets/images/notification_icon.svg) no-repeat center
    center;
  position: relative;
  left: 20px;
}
.profile {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #3e38a1 url(../Assets/images/cartImg.svg) no-repeat center center /
    18px auto;
  border-radius: 50%;
}
.notify {
  display: flex;
  justify-content: end;
  justify-self: flex-end;
}
.counts {
  position: absolute;
  left: -16px;
  top: 14px;
  background: #d65b4c;
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 1.5;
  border: 2px solid #fff;
  font-size: 15px;
}
.link-box {
  padding: 0 5px 15px;
  position: relative;
}
.link-box.active:after {
  content: "";
  display: inline-block;
  width: calc(100% - 10px);
  height: 5px;
  background: #ccc;
  bottom: 4px;
  position: absolute; /*max-width: 85px;*/
  left: 0;
  right: 0;
  margin: auto;
}
.link-box a,
.link-box .box {
  display: flex;
  height: 85px;
  width: 85px;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 5px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin: auto;
  text-align: center;
  line-height: normal;
}
.link-box .blue {
  background: #3e38a1;
}
.link-box .purple {
  background: #9359a5;
}
.link-box .yellow {
  background: #f6bf1e;
}
.link-box .green {
  background: #63c6b4;
}
.link-box .pink {
  background: #d92a93;
}
.menu-row {
  flex-wrap: nowrap;
  padding: 0 10px;
  overflow: auto;
}
.menu-box {
  margin: 0;
  background: #fff;
  padding: 7px 15px;
  position: relative;
}
.menu-box a {
  text-decoration: none;
}
/* .menu-box::after {
  content: "";
  border-bottom: 1px solid #e8e8e8; 
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
} */
.menu-img {
  padding: 0;
  margin: 0;
}
.menu-content {
  padding-right: 0;
}
.menu-content h4 {
  font-weight: bold;
  font-size: 15px;
  color: #585555;
  /* margin: 0 auto 5px; */
  /* margin: 3px auto 4px; */

  margin: 0 0 4px;
  max-width: 77%;
}
.txt-green {
  color: #1cbec3;
}
.txt-pink {
  color: #d51b67;
}
.menu-content p {
  color: #5a5655;
  font-size: 13px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin-bottom: 0px;
  line-height: normal;
}
.menu-content p.pricing {
  font-size: 15px;
}
.menu-content p.pricing.strike-off {
  /* font-size: 16px; */
  text-decoration: line-through;
  white-space: nowrap;
}
.menu-content p.promotion-price {
  font-size: 15px;
  color: #d51b67;
  white-space: nowrap;
}
.menu-sections .menu-box.active::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: calc(100% - 20px);
  position: absolute;
  /* background: #d51b67; */
  left: 0;
  top: 10px;
}
.menu-sections .title,
.cart-sec-ms .title {
  font-weight: bold;
  font-size: 18px;
  padding: 10px 15px 10px 15px;
  margin: 0;
  text-transform: capitalize;
  background: #f5f6f7;
}
.menu-sections {
  max-height: calc(100vh - 345px);
  background: #fff;
  overflow: auto;
}
.bottom-sec {
  color: #fff;
  padding: 15px 0px;

  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;
  left: 0;
  right: 0;
  margin: 0 auto;

  background-color: #f5f6f7;
}
.bottom-sec .green-sec {
  margin: 0;
  display: flex;
  justify-content: space-between;
  background: #3e38a1;
  padding: 6px 5px;
  border-radius: 5px;
  align-items: center;
  color: #fff;
}
.items-count {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background: #5750d1;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
}
.green-sec .total-price strong {
  font-size: 23px;
  display: block;
  margin-bottom: -8px;
}
.green-sec .total-price strong small {
  font-weight: bold;
  font-size: 70%;
}
.total-price {
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}
.green-sec strong {
  font-size: 20px;
  color: #fff;
}
.welcome-sec {
  align-items: baseline;
  margin-top: -35px;
  align-items: flex-end;
}
.welcome-sec p {
  color: #858585;
}
.ratings-sec p {
  margin: 0 auto 8px;
}
.ratings-sec {
  width: 100%;
  padding: 10px 0;
}
.ratings-sec .ratings {
  border: 1px solid #bababc;
  padding: 6px 40px;
  border-radius: 40px;
  background: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.ratings .star {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../Assets/images/star.svg) no-repeat center center;
}

/*Add items sec*/
.image-sec figure {
  margin: 0;
}
.item-details {
  background: #f5f6f8;
  margin-top: -63px;
  position: relative;
  border-radius: 30px 30px 0 0;
  padding: 0px 0;
  overflow: hidden;
}
.product-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;
  padding: 22px 20px 18px;
  align-items: center;
}
.product-details p {
  margin: 10px auto 0;
}
.product-details .name,
.product-details .price {
  padding: 0;
  margin: 0;
}
.product-details .price {
  text-align: right;
  font-size: 18px;
}
.sml-title {
  margin: 0;
  padding: 15px 20px;
  font-weight: bold;
  color: #5b5b5d;
}
.product-details .name {
  font-weight: bold;
  font-size: 18px;
  color: #3d3d3d;
}
.item-details .selectors .options {
  /* padding: 0px 0 0px;
  margin: 5px auto; */
}

.item-details .selectors .options {
  padding: 15px 0 15px;
  border-bottom: 1px solid #ddd;
}
.item-details .selectors .options:last-child {
  border: 0;
}
.options label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #bababc;
  margin-right: 15px;
}
.options input.check:checked + label:before {
  background: #ccc;
}
.options label {
  display: flex;
  align-items: center;
  color: #595556;
  margin: 0;
}
input.check {
  display: none;
}
.selectors {
  padding: 8px 20px;
  background: #fff;
}
.item-details .selectors {
  padding-bottom: 30px;
}
.options {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
}
.price-sm {
  color: #595556;
}
.items-sec {
  display: flex;
  align-items: center;
}
.remove-btn,
.add-btn {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bababa;
  padding: 0;
  line-height: 0.6;
}
.items-num {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  text-align: center;
  font-weight: bold;
  color: #535353;

  width: 45px;
  height: 40px;
  font-size: 22px;
}
.adding-items {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  background: #fff;
  margin: 10px auto 0;
}
.price-sec p {
  margin: -5px 0 0 0;
  text-transform: uppercase;
}
.price-sec strong {
  font-size: 22px;
  color: #3e38a1;
}

.scroll-active .add-items_sec .item-details .product-details .description {
  margin-top: 30px;
}

/* start labels */

.add-items_sec .options label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
}
.add-items_sec .options label:before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-color: var(--store-color);
}
.add-items_sec .options input:checked + label:before {
  background: transparent;
}
.add-items_sec .options input:checked + label:after {
  position: absolute;
  content: "";
  background-color: #3e38a1;
  height: 10px;
  width: 10px;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  border-radius: 3px;
}

/* end of labels */
.price-sec {
  text-align: right;
}
/* .add-btn {
  background: url(../Assets/images/plus.svg) no-repeat center center / 14px;
}
.remove-btn {
  background: url(../Assets/images/minus.svg) no-repeat center center / 14px;
} */
.green-btn {
  background: #3e38a1;
  border: 0;
  color: #fff;
  border-radius: 5px;
  height: 56px;
  width: 100%;
  font-weight: bold;
  max-width: calc(100% - 30px);
  margin: 15px auto 0;
  float: none;
  display: block;
  padding: 10px 0;
  font-size: 18px;
  text-transform: uppercase;
}

.add-items_sec + .bottom-sec .green-btn {
  margin-top: 0;
}
.sub-btn button {
  max-width: calc(100% - 30px);
  margin: 0 auto;
  float: none;
  display: block;
  padding: 10px 0;
  font-size: 18px;
  text-transform: uppercase;
}

.cart-screen .sub-btn button,
.rider-note-modal .sub-btn button,
.group.sub-btn button {
  max-width: 100%;
  text-transform: none;
  font-size: 18px;

  text-transform: uppercase;
}
.user-section.Delivery .sub-btn button {
  max-width: none;
  text-transform: none;
  font-size: 18px;
  margin: 0;

  text-transform: uppercase;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  width: 100% !important;
}

.css-122dlte-MuiFormControl-root .MuiInputBase-root input {
  padding: 10.5px 0px 10.5px 20px !important;
}

.cross-btn,
.cross-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  background: url(../Assets/images/cross_wht.svg) no-repeat center center;
  left: 12px;
  top: 12px;
  /* box-shadow: 1px -2px 10px black; */
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.4);
}

/*cart page*/
.hotel_name {
  justify-content: center;
  margin: 20px auto 20px;
  align-items: center;
}
.hotel_name .naming-sec p {
  color: #858585;
  line-height: 1;
}
.back,
a.back,
a.back:hover {
  padding-left: 20px;
  color: #d51b67;
  font-weight: bold;
  background: url(../Assets/images/back.svg) no-repeat center left / 8px;
  text-decoration: none;
  font-size: 17px;
}
h5.green-title {
  color: #d51b67;
  font-weight: bold;
  font-size: 17px;
  margin: 0;
}
.cart-sec-ms .menu-content h4 {
  font-size: 15px;
}
.other-options {
  padding: 0 0 0 21px;
  line-height: normal;
  margin: 0;
}
.pricing-lg {
  font-size: 18px;
  margin: 0;
}
.cart-sec-ms .menu-box {
  align-items: center;
  /* margin: 0px 23px; */
  padding: 15px 0px;
}

.border-btm-grey {
  border-bottom: 2px solid #ddd !important;
}

.billing-details {
  background: #fff;
  /* max-height: 35vh;
  overflow: auto; */
  height: auto;
  padding-bottom: 120px !important;
}
.billing-list {
  padding: 0px 20px;
  background: #fff;
  /* margin: 10px auto; */
}
.billing-list li {
  padding: 5px 0;
  /* border-bottom: 1px solid #e8e8e8; */
  display: flex;
  justify-content: space-between;
  color: #8e8e8e;
  align-items: center;
  height: 45px;
}
.billing-list li span {
  font-weight: bold;
  color: #8d8d8d;
  text-transform: uppercase;
  font-size: 14px;
}
.billing-list li strong {
  color: #5b5c5e;
  font-size: 18px;
}
.billing-list .type-text {
  text-align: right;
  color: #b7b5b6;
  background: #fff;
  width: 80px;
  height: 35px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-weight: bold;
}

.billing-list li.total span {
  font-size: 17px;
  color: #5b5c5e;
}
.billing-list li.total strong {
  /* font-size: 25px; */
}
.link,
a.link,
a.link:hover {
  font-size: 16px;
  font-weight: bold;
  color: #d51b67;
  text-decoration: none;
}

.cart-screen .link,
.cart-screen a.link,
.cart-screen a.link:hover {
  font-size: 14px;
}
.new_items .menu-box::before {
  /* content: ""; */
  width: 7px;
  height: calc(100% - 10px);
  position: absolute;
  background: #a5a5a5;
  left: 0;
  top: 5px;
}
.new_items .menu-box.slide::before {
  content: none;
}
.green-btn img {
  max-width: 40px;
  margin: auto;
}
.btn-sec a.green-btn,
.btn-sec a.green-btn:hover {
  text-align: center;
  color: #fff;
  text-decoration: none;
}
.varification-sec {
  background: #fff;
  z-index: 1;
  position: relative;
  margin: 20px auto 0;
  border-radius: 20px 20px 0 0;
  padding: 20px 0 0;
  display: flow-root;
}
.varification-sec .circle-img {
  margin: 0px auto;
}
.overlay {
  background: #f5f6f8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.cross-icon {
  background: url(../Assets/images/cross_gry.svg) no-repeat center center;
  left: auto;
  right: 10px;
}
.title {
  font-weight: bold;
}

.title-heading {
  color: #595556;
}
.num {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #e8e8e8;
  text-align: center;
  border-radius: 50%;
  line-height: 1.8;
  font-weight: bold;
  margin-right: 10px;
}
.input-text {
  border-radius: 5px;
  margin: 20px auto;
  width: 100%;
  border: 1px solid #bababc;
  height: 45px;
  font-size: 14px;
  padding: 0 10px;
}
.login-form .btn.green-btn {
  width: auto;
  font-size: 15px;
  width: 120px;
}

.or::before {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
  left: 0;
  top: 50%;
}
.or span {
  color: #9f9f9f;
  text-transform: uppercase;
  font-weight: bold;
  background: #fff;
  border-radius: 50%;
  position: relative;
  padding: 10px;
}
.or {
  position: relative;
  width: 80%;
  text-align: center;
  margin: 20px auto;
}

.qr-sec {
  width: 65%;
  margin: 20px auto 0;
}
.qr-sec figure {
  border: 2px dashed #dddee0;
  padding: 20px;
}
.qr-sec .btn-sec {
  padding: 15px 0;
}
.qr-sec .green-btn img {
  max-width: 30px;
  margin: 0 auto 5px;
}
.qr-sec .green-btn {
  padding: 10px 0px;
  font-size: 15px;
  height: auto;
}
.also-like-sec {
  margin: 0;
  padding: 10px 20px;
  background: #fff;
}
.also-like-sec img {
  max-width: 80px;
  margin: 0 auto 10px;
}
.also-like-sec {
  text-align: center;
}
.sm-h5 {
  font-weight: bold;
  font-size: 16px;
  color: #595556;
  margin: 0;
}
.also-like-sec .col-6 {
  padding: 0;
}

.loyality_ms .middle-sec {
  background: #fff;
  position: relative;
}
.info {
  text-align: center;
}
.info p {
  text-transform: uppercase;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
  color: #9f9f9f;
  flex: 0 0 100%;
}
.info h4 {
  margin: 0;
  color: #5e5e60;
  font-weight: bold;
}
.account-info .title,
.fav-info .title,
.history-info .title {
  color: #3e3e3e;
  padding: 15px 20px;
  margin: 0;
}
.info .visits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-end;
}
.border-right {
  border-right: 1px solid #e8e8e8 !important;
}
.info-sec,
.fav-sec {
  background: #fff;
  position: relative;
  margin: 15px auto 0;
}
.info-details label,
#promotions label,
#vouchers label {
  color: #9f9f9f;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.tag {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  background: #ccc;
  width: 100px;
  text-align: center;
  font-weight: bold;
}
.tag.green {
  background: #9cd8d8;
  color: #105f5a;
}
.info-details p {
  font-size: 15px;
  color: #575654;
}
.info-details > div {
  margin-top: 10px;
}
.fav-sec .menu-content h4 {
  font-size: 16px;
  margin: 0;
}
.fav-sec .menu-content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
.fav-sec .menu-content p.pricing {
  font-size: 17px;
}
.square-img p {
  margin: 0;
  color: #fff;
  flex: 0 0 100%;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
.square-img {
  text-align: center;
  color: #fff;
  background: #63c6b4;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 50px;
  width: 60px;
  border-radius: 5px;
  float: right;
}
.square-img h5 {
  flex: 0 0 100%;
  margin: 0 auto 0px;
  font-weight: bold;
  font-size: 14px;
}
.history-info h2.pricing {
  color: #696a6c;
  font-weight: bold;
  text-align: right;
}
.history-info h2.pricing small {
  font-weight: bold;
  font-size: 60%;
}
.history-info p {
  color: #595554;
  line-height: normal;
}
.history-info .naming-sec h4 {
  margin-bottom: 6px;
}
.more-btn {
  border: 0;
  display: inline-block;
  font-weight: bold;
  color: #1cbec3;
  background: url(../Assets/images/up-arrow-green.png) no-repeat 10px 10px /
    18px;
  font-size: 18px;
  float: right;
  padding: 0 0 0 40px;
}
.more-btn.open {
  background: url(../Assets/images/down-arrow-green.png) no-repeat 10px 10px /
    18px;
}
.btns .more-btn,
.btns .more-btn.open {
  background-position: 10px 20px;
}
.bill-details {
  border-top: 2px solid #e8e8e8;
  max-width: calc(100% - 0px);
  margin: auto;
}
.bill-details ul {
  margin: 0;
  padding: 10px 0 0;
}
.bill-details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.bill-details li label {
  font-weight: bold;
  color: #8e8e8e;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
}
.bill-details h4 {
  font-size: 19px;
  font-weight: bold;
  color: #535353;
  margin: 0;
}
.bill-details li.total label,
.bill-details li.total h4 {
  color: #1cbec3;
}
.bill-details li.total {
  border-top: 2px solid #e8e8e8;
}

.history_details,
.promotion_details,
.vouchers_details {
  padding: 10px 0;
  border-bottom: 2px solid #e8e8e8;
  max-width: calc(100% - 30px);
  margin: auto;
}
.loyality_ms .link-box a,
.loyality_ms .link-box .box {
  aspect-ratio: auto 1 / 1;
  width: 100%;
  height: 100%;
}
.btns {
  justify-content: space-between;
}
.green-btn.btn-sm {
  font-size: 15px;
  text-transform: uppercase;
  height: 45px;
  width: 120px;
  border-radius: 5px;
}
#promotions .more-info p,
#vouchers .more-info p {
  font-size: 18px;
  margin: 0;
}
.promotion_details figure,
.vouchers_details figure {
  margin: 0;
}
.more-info .col-4 {
  margin-top: 15px;
}

.voucher-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;
  max-width: 80%;
  margin: 0 auto 30px;
}
.voucher-sec li {
  margin: 0 10px;
}
.voucher-sec li a {
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  color: #9f9f9f;
}
.voucher-sec li.active a {
  border-bottom: 6px solid #3e38a1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper .menu-row::-webkit-scrollbar,
.wrapper .recommended_slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper .menu-row,
.wrapper .recommended_slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom_pb {
  padding-bottom: 92px;
  /* min-height: 100vh; */
  height: calc(100vh - 60px);
}
.custom_pb .bottom-sec {
  position: fixed;
  /* position: sticky; */
  bottom: 0;
  width: 100%;
  background-color: #f5f6f7;
  box-shadow: none;
  outline: none;
  border: none;
  border: 1px solid #f5f6f7;
  max-width: 767px;
  z-index: 1;
}
body #root.ios-device .custom_pb {
  padding-bottom: 0;
  height: 100vh;
}

body #root.ios-device {
  max-height: calc(100vh - 108px);
}
/* body #root.ios-device {
  max-height: calc(100vh - 60px);
} */

.top-scroll .product-details .price {
  visibility: hidden;
}
.scroll_active .add-items_sec .image-sec img {
  height: 110px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.scroll_active .add-items_sec .item-details {
  height: calc(100vh - 225px);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.scroll_active .menu-sections {
  height: calc(100vh - 164px);
  padding-bottom: 10vh;
}
.scroll_active .menus_row .menu-row {
  width: calc(100% - 45px) !important;
  padding: 0;
  margin-left: 20px !important;
}
.scroll_active .menus_row {
  padding: 0 15px;
}
.scroll_active .menus_row .filter_icon {
  /* opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; */
  display: block;
  margin-top: -17px;
}
.scroll_active .middle-sec .menu-row .col.active:after {
  display: none;
}

.scroll_active .middle-sec .menu-row .link-box {
  padding: 0 5px 15px;
}

/* Issue on scrolling  */
/* .scroll_active .menu-sections {
  height: calc(100vh - 225px);
  /* max-height: calc(100vh - 250px); */
/* -webkit-transition: 0.3s all;
  transition: 0.3s all; */

.scroll_active .menu-sections {
  height: calc(100vh - 145px);
  max-height: none;
}
.custom_pb {
  padding: 0;
}

.scroll_active .middle-sec .menu-row .col a {
  width: max-content;
  padding: 0 7px;
}

.scroll_active .middle-sec .menu-row .col.active a {
  background: #3e38a1;
  color: #fff;
  /* -webkit-transition: 0.05s all;
  transition: 0.05s all; */
}
.scroll_active .middle-sec .menu-row .col a {
  height: 40px;
  background: transparent;
  color: #3e38a1;
  width: max-content;
  padding: 0 7px;
}
.scroll_active .menu-row {
  margin-left: 10px !important;
  margin-top: 15px;
}

.send-btn {
  width: 50px;
  border: 0;
  background-color: transparent;
  /* transform: scale(0.8); */
}
.send-btn svg {
  width: 25px;
}
.recived-msg-sec {
  width: 100%;
}
.recived-box,
.send-box {
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  align-items: center;
  background: #fff;
  /* border: 1px solid #b8b8b8; */
  border: 1px solid #929292;
  font-size: 14px;
  text-align: left;
  padding: 15px 15px;
  border-radius: 8px;
  position: relative;
}
.send-msg-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 70%;
  float: right;
  margin: 0 0 20px;
  width: 100%;
}
.recived-msg-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 70%;
  margin: 0 0 20px;
}

.name-time {
  font-size: 12px;
  color: #878787;
  flex: 0 0 100%;
  width: 100%;
  margin: 20px auto 0;
}

.recived-msg-sec .name-time {
  text-align: left;
}
.send-msg-sec .name-time {
  text-align: right;
}
.chat-section {
  width: 100%;
  padding: 20px 20px 100px;
  background: #f5f5f5;
  position: relative;
  /* height: 56vh; */
  height: calc(100vh - 360px);
}
textarea.chat-text {
  display: flex;
  width: 100%;
  flex: 0 0 85%;
  align-items: center;
  background: #fff;
  border: 1px solid #b8b8b8;
  border: 1px solid #929292;
  font-size: 14px;
  text-align: left;
  padding: 15px 15px;
  border-radius: 8px;
  resize: none;
  height: 80px;
}
.chat-box {
  display: flex;
  width: 100%;
  justify-content: space-between;

  position: absolute;

  bottom: 10px;
  width: calc(100% - 40px);
  left: 20px;
  right: 20px;
}

.recived-box:after,
.recived-box:before,
.send-box:after,
.send-box:before {
  top: 100%;
  left: 13%;
  border: solid transparent;
  /* content: ""; */
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.send-box:after,
.send-box:before {
  right: 13%;
  left: auto;
}

.recived-box:after,
.send-box:after {
  border-color: rgba(250, 250, 255, 0);
  border-top-color: #fafaff;
  border-width: 8px;
  margin-left: -8px;
}
.send-box:before,
.recived-box:before {
  border-color: rgba(209, 209, 209, 0);
  border-top-color: #999999;
  border-width: 9px;
  margin-left: -9px;
}

.chat-sec {
  max-height: 100%;
  position: relative;
  overflow: auto;
}

.add-items_sec {
  position: relative;
  max-height: calc(100vh - 140px);
}

.add-items_sec .image-sec,
.add-items_sec .image-sec .carousel-root,
.add-items_sec .image-sec .carousel-slider,
.add-items_sec .image-sec .slider-wrapper,
.add-items_sec .image-sec .slider-wrapper .slider {
  height: 100%;
}

.add-items_sec .image-sec figure {
  filter: brightness(0.5);
  background: #003e40;
}
.add-items_sec .image-sec img {
  width: 100%;
  height: 280px;
  object-fit: contain;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.add-items_sec .item-details {
  height: calc(100vh - 400px);
  overflow: auto;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.add-items_sec .item-details .product-details .currency {
  font-size: 14px;
}

.add-items_sec .item-details .sm-title {
  font-size: 16px;
}
.add-items_sec .item-details > .sm-title {
  background: #f5f6f8;
}
.item-details {
  background: #fff;
}
.add-items_sec .item-details .product-details .description {
  text-align: left;
  margin-left: unset;
  width: 100%;
  margin-top: 6px;
}

.scroll-active .add-items_sec .item-details .product-details .description {
  margin-top: 30px;
}

.add-items_sec .image-sec .tags {
  position: absolute;
  top: 12px;
  right: 0;
  background-color: #3526a7;
  color: #fff;
  padding: 4px 13px;
  width: 200px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.add-items_sec .image-sec .tags.blue {
  background-color: #1ebdc3;
}
.add-items_sec .item-details .product-details .labels {
  text-align: right;
  margin-left: auto;
  margin-top: 10px;
}
.add-items_sec .item-details .product-details .labels ul {
  display: flex;
}
.add-items_sec .item-details .product-details .labels li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}
.add-items_sec .item-details .product-details .labels li:not(:last-child) {
  margin-right: 5px;
}

.add-items_sec .item-details .product-details .options {
  width: 100%;
  padding: 0;
}
.add-items_sec .item-details .product-details .options ul {
  width: 100%;
}
.add-items_sec .item-details .product-details .options li {
  width: 100%;
  display: flex;
  margin-top: 15px;
}
.add-items_sec .item-details .product-details .options li .price {
  margin-left: auto;
}
.add-items_sec .item-details .product-details .options label:before {
  border-radius: 50%;
}
.add-items_sec .item-details .message_col textarea {
  height: 100px;
  border: none;
  padding: 0;
  resize: none;
  font-size: 16px;
}

.add-items_sec
  .item-details
  .product-details
  .options
  input:checked
  + label:after {
  border-radius: 50%;
}

.add-items_sec .carousel .slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.add-items_sec .carousel .slide div {
  height: 100%;
}

.add-items_sec .carousel .control-arrow {
  opacity: 1 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff !important;
  border-radius: 50%;
  top: calc(50% - 30px) !important;
  transform: translateY(-50%);
}

.add-items_sec .carousel .control-arrow:before {
  opacity: 0.9;
}

.add-items_sec .carousel .control-arrow.control-prev {
  left: 10px;
}

.add-items_sec .carousel .control-arrow.control-next {
  right: 10px;
}

.add-items_sec .carousel .control-disabled.control-arrow {
  opacity: 0 !important;
}

.add-items_sec #ReactSimpleImageViewer {
  z-index: 9999;
}

.add-items_sec
  #ReactSimpleImageViewer
  .react-simple-image-viewer__modal-content {
  width: 100%;
}

.add-items_sec .react-simple-image-viewer__slide img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__next,
.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__previous {
  opacity: 1;
  font-size: 22px;
  font-weight: 400;
  padding: 0 10px;
  display: none;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__close {
  opacity: 1 !important;
}
.image-container-sec {
  overflow: hidden;
}

.foobar-header h5 {
  font-size: 18px;
  font-weight: bold;
}

.foobar-header {
  z-index: 9;
  position: relative;
  background: #fff;
  overflow: hidden;
  opacity: 0;
}

.foobar-header.touch-top {
  padding: 25px 20px 25px 50px;
  opacity: 1;
  z-index: 10;
}

.foobar-header.touch-top .cross-btn {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
.foobar-header.touch-top .cross-btn {
  top: 23px;
}

.foobar-header p.description {
  overflow: auto;
  height: 100%;
}

.foobar-header.touch-top .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}

.add-items_sec .item-details.top-scroll {
  height: calc(100vh - 170px);
  z-index: 9;
}
#root.ios-device .add-items_sec .item-details.top-scroll {
  height: calc(100vh - 186px);
}

.bottom-sec.flex_btns .adding-items {
  max-width: 100%;
  flex: 0 0 100%;
}

.scroll-active .add-items_sec .item-details .product-details .description {
  margin-top: 10px;
  max-width: calc(100% - 50px);
}

/* loading-overlay */
.loading-overlay {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

/* page not found */
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

.error_container .container div {
  height: auto !important;
}

.error_container .not-found-menu {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.error_container .not-found-menu {
  min-height: calc(100vh - 58px);
}

.error_container .not-found-menu h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error_container .not-found-menu .error-details {
  line-height: 20px;
}

.error_container .not-found-menu figure {
  height: 60px;
  width: 60px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.menu-row .link-box > a.overlay-show:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.wrapper.scroll_active .menu-row .link-box > a.overlay-show:after {
  display: none;
}
.menu-row .link-box > a.overlay-show {
  position: relative;
}
.menu-row .link-box > a.overlay-show span {
  position: relative;
  z-index: 1;
}

.menu-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  min-width: 82px;
  /* max-width:75px; */
  border-radius: 4px;
  overflow: hidden;
  padding: 0 !important;
}
.menu-img img {
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

.add-items_sec .item-details .message_col label {
  color: #5b5b5d;
  font-weight: bold;
  font-size: 16px;
}

.menu-box .tag {
  position: absolute;
  right: 0;
  top: 8px;
  background-color: var(--dark-blue);
  border-radius: 0;
  text-align: left;
  color: #fff;
  justify-content: flex-start;
}
.menu-box .tag.blue {
  background: #3e38a1;
}
.menu-box .label_row {
  display: flex;
  align-items: center;
  margin: 4px auto 0;
}
.menu-box .label_row ul {
  display: flex;
  margin-left: auto;
}
.menu-box .label_row ul li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}
.menu-box .label_row ul li:not(:last-child) {
  margin-right: 5px;
}

.menu-sections {
  background-color: #fff;
}

.menu-sections .menu-box::before {
  display: none;
}

.menu-sections .menu-box:after {
  content: "";
  /* border-bottom: 1px solid #e8e8e8; */
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #e8e8e8 !important;
}

.menu-box:last-child::after {
  display: none;
}

.new_items_page .draggable_bg:last-child .menu-box:after {
  display: none !important;
}
.menu-box.disabled {
  opacity: 0.2;
}

.bottom-sec.disabled .sub-btn button {
  opacity: 0.6;
}

.edit-btn {
  background: #65c5b5;
}
.delete-btn {
  background: #d4594c;
}
.cart-sec-ms .menu-box {
  overflow: hidden;
  min-height: 77px;
  padding: 13px 15px;
}
.bottom-btn-sec {
  position: fixed;
  bottom: 20px;
  width: auto;
  left: 0;
  right: 0;
}
.orderMenu {
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 0px 0;
  min-height: 52px;
  height: 100%;
  justify-content: space-between;
}
.draggable_bg .delete_box {
  background: none;
  left: auto;
  right: 0;
  width: 180px;
}
.draggable_bg .delete_box:after {
  content: "";
  width: 100%;
  right: 15px;
  bottom: 0;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
}

.edit_delete {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
}
.edit_delete a:hover,
.edit_delete a:focus {
  color: #fff;
}

/*12-1-22*/
.login-section label {
  font-size: 14px;
}
/* .colorBg .login-section, .colorBg { max-height: 100vh;} */
.login-section .logo-sec {
  margin: 5vh 0 0vh;
}

.hotel_name.con_back.hideUpper {
  margin: 5px 0px 15px 0px;
}
.back_link_con.hideUpper {
  margin: 10px 0px 0px 0px;
}

.sm-heading {
  font-weight: bold;
  font-size: 18px;
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  display: none;
}

.menu-sections .title,
.cart-sec-ms .title {
  font-size: 16px;
  padding: 16px 15px;
}

.Toastify__toast {
  min-height: 0 !important;
}
.Toastify__toast-body {
  padding: 0 6px !important;
  margin: 0 !important;
}
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right,
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right {
  bottom: auto !important;
  top: 0 !important;
  -webkit-transform: translateX(0);
}

.maping {
  overflow: hidden;
}
.Toastify__close-button {
  z-index: 99;
}
.px-20 {
  padding: 0 20px;
}

.side-bar {
  position: absolute;
  width: 8px;
  height: calc(100% - 14px);
  top: 7px;
  left: 0;
  z-index: 1;
  background-color: #d51b67;
}
/* 
.css-14vyde3-MuiCollapse-wrapperInner { width:100% !important; max-width: 100%;}
.leaflet-safari .leaflet-tile-container { width:100% !important; height:100% !important;max-width:100%; max-height: 100%;
  display: inline-block;
  min-width: 100vw !important;
  min-height: 90vw;} */

.draggable_bg .menu-box.slide .react-reveal {
  position: absolute;
  right: 10px;
  top: 22%;
}
.menu-box.slide .orderMenu {
  right: 145px;
}

.orderMenu {
  transition: all 0.5s;
}

.draggable_bg .react-reveal {
  position: absolute;
  top: 22%;
}

span.currency_ico {
  position: absolute;
  left: 0;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.css-6hp17o-MuiList-root-MuiMenu-list,
div#menu- .MuiList-root,
div#menu- .MuiPaper-root.MuiPaper-elevation {
  max-width: none !important;
  max-height: 40vh !important;
  display: inline-block;
  width: 92vw;
  border-radius: 20px !important;
  padding: 0 !important;
} */

.pb-8 {
  padding-bottom: 80px !important;
}

.h6 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.step-master .h6 {
  text-transform: none;
}
.chat-container {
  height: 84.8vh;
}

.bottom-div {
  display: flex;
  width: 100%;
}
.countrySelect .MuiOutlinedInput-root {
  border-bottom: 1px solid #3e38a1 !important;
  height: 37px;
}
.countrySelect
  .css-1q1573o-MuiInputBase-root-MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-outlined {
  min-width: 100%;
  padding: 10px 5px;
}
.countrySelect
  .css-1q1573o-MuiInputBase-root-MuiOutlinedInput-root.MuiInputBase-root
  svg {
  margin-left: -32px;
}
.maxBtn {
  max-width: 767px;
}
.maxBtn button {
  box-shadow: 0 7px 14px 0 rgb(49 49 93 / 10%), 0 3px 6px 0 rgb(0 0 0 / 8%);
}
.item.forward-enter.forward-enter-active {
  position: relative;
  z-index: 9;
  height: 100vh;
  min-height: 100vh;
}
.forward-exit-active,
.back-enter-active {
  /* display: none; */

  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  transition: none !important;
  transform: none !important;
  height: 100vh;
  min-height: 100vh;
}
.slide-routes {
  display: block !important;
  /* height: 100vh;
  min-height: 100vh; */
}

.MuiSelect-select {
  font-size: 14px !important;
}
.MuiFormLabel-filled {
  font-size: 14px !important;
  top: -10px !important;
}
.MuiSelect-outlined {
  margin-left: -20px;
}

.updateAddress {
  /* position: sticky;
  right: 0; */

  position: absolute;
  right: 14px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}

.step-master .css-14sza3e-MuiStepLabel-root {
  align-items: start;
  position: relative;
}
.step-master .css-vnkopk-MuiStepLabel-iconContainer {
  margin-top: -5px;
}
.step-master .css-1cfef1q .MuiStepConnector-root {
  top: 35px;
  transform: translate(0, 0%);
  /* height: calc(100% - 70px); */
  height: calc(100% - 102px);
}
.add-address-icon {
  margin-left: -10px;
}
/* .cross-address-icon {
  width: 18px;
  right: 10px;
  top: 0px;
  position: relative;
} */

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}
.addSecPopup .MuiOutlinedInput-notchedOutline,
.countrySelect .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
.cartDiv {
}

.step-master
  .MuiStep-vertical:nth-child(3)
  .css-vnkopk-MuiStepLabel-iconContainer
  figure:after {
  content: "";
  display: inline-block;
  width: 23px;
  height: calc(100% - 50px);
  position: absolute;
  background: #fff;
  top: 50px;
  left: 0;
  right: 0;
}

.trackStatus .sub-btn button {
  margin: 0;
  max-width: none;
  width: 100%;
}
.OrderDetail figure svg {
  width: 100%;
}

.statusTracking figure {
  display: flex;
}

.send-box:before {
  display: none;
}
.send-box:after,
.recived-box:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../Assets/images/drop_arrow.svg) no-repeat center top;
  border: 0;
  margin-top: -2px;
}
.css-cveggr-MuiListItemIcon-root {
  /* min-width: 46px !important; */
  justify-content: center;
}
textarea.chat-text {
  /* margin-right: 12px; */
  flex: 0 0 calc(100% - 55px);
  color: rgba(0, 0, 0, 0.87);
}
.send-btn {
  width: 35px;
  flex: 0 0 35px;
  padding: 0;
}

.MuiListItem-root.MuiListItem-gutters {
  justify-content: space-between;
}
.MuiListItem-root.MuiListItem-gutters > div:nth-child(1) {
  flex: 0 0 calc(100% - 55px);
}
.MuiListItem-root.MuiListItem-gutters > div:nth-child(2) {
  flex: 0 0 35px;
  justify-content: center;
  min-width: 0;
}

.Toastify__toast--success {
  background: #3cc1c0 !important;
}

.sm-title.font-normal {
  font-weight: normal;
}
.sm-title.font-normal b {
  font-weight: bold;
}

.MuiStepLabel-label {
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 15px !important;
  font-family: "Myriad Pro" !important;
}

.anchor_link.bolder {
  font-weight: bold;
  font-size: 14px;
}

.login-section.OtpVerifyMs {
  align-content: start;
  min-height: 0vh;
  height: auto;
}

.css-1ozefeb {
  z-index: 1300;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto !important;
  left: 50% !important;
  top: 50% !important;
}
.cartDiv {
  right: 12px;
  top: 20px;
}

.mt-16 {
  margin-top: 0px;
}
.OtpVerifyMs .group label:not(:last-child) {
  border: 0 !important;
  font-family: "Myriad Pro";
}

.OtpVerifyMs input,
.OtpVerifyMs input {
  -webkit-appearance: none !important;
  appearance: none !important;
  border: 0;
  border-radius: 0 !important;
}

.OtpVerifyMs .Payment .group label > span {
  font-size: 14px !important;
}
.OtpVerifyMs .Payment .group.cardDetails-sec label {
  margin-bottom: 20px !important;
}

.login-section label {
}
.rider-note-modal .title {
  font-size: 18px !important;
}
.p16 {
  font-size: 16px;
}
.menu-sections p.p16 {
  color: #363636;
}
.selectInput.twoInp fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* .slide-routes.slide ul li span, */
.statusTracking p,
ul.MuiList-root li > div > span {
  font-family: "Myriad Pro" !important;
}

.calendar legend {
  max-width: 0px !important;
}

.group .selectInput.twoInp .MuiOutlinedInput-root {
  margin: 0 10px 0 0;
}

.MuiStepConnector-root {
  /* top: 50% !important;
  transform: translate(0, -50%) !important; */
  top: 15px !important;
  transform: translate(0, -0%) !important;
}

.wht-bg {
  background: #fff;
  padding: 20px 20px 0;
}
/* .clock-cal {
  display: flex;
  max-width: 352px;
  margin-right: auto;
  justify-content: space-between;
} */

.clock-cal fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.selectInput.selectBox svg {
  position: absolute;
  right: 0;
}
.countrySelect.selectBox svg {
  right: -12px;
}

.selectInput.selectBox .MuiSelect-select,
.countrySelect.selectBox .MuiSelect-select {
  width: 100%;
  position: relative;
  z-index: 1;
}

.css-1clwhh8-MuiInputBase-root-MuiOutlinedInput-root.MuiInputBase-root.Mui-focused
  > div[aria-expanded="false"]
  ~ svg {
  transform: rotate(0) !important;
}

.bckLive_Updates button {
  padding: 0;
  text-align: center;
  justify-content: center;
}

.MuiSelect-select,
.ElementsApp,
.ElementsApp .InputElement {
  color: #333 !important;
  font-size: 14px !important;
  font-family: "Myriad Pro" !important;
}

.OtpVerifyMs input,
.OtpVerifyMs input {
  color: #333;
  font-size: 14px !important;
  font-family: "Myriad Pro" !important;
}

.menu-img {
  max-width: 86px;
}

.selectInput.twoInp .MuiOutlinedInput-input {
  font-family: "Myriad Pro" !important;
  font-size: 14px !important;
}

.menu_sec {
  margin-bottom: 15px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}
#basic-button {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 50%;
}

#basic-menu .MuiMenu-list {
  padding: 0 10px;
  width: calc(100% - 0px);
  max-height: 340px;
  overflow: scroll;
}
#basic-menu :first-child {
  justify-content: center;
}
#basic-menu .MuiMenu-list li {
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Myriad Pro";
  padding: 10px 6px;
  background: #fff;
}
#basic-menu .MuiMenu-paper {
  border-radius: 15px;
  margin-top: 18px;
  border: 2px solid #ddd;
  right: 5px;
  overflow: visible;
}

#basic-menu .MuiMenu-paper::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../Assets/images/arrow-up.svg) no-repeat center bottom;
  position: absolute;
  /* transform: rotate(-180deg); */
  right: 10px;
  top: -20px;
  /* opacity: 0.4; */
}

.menu-row::-webkit-scrollbar {
  display: block;
  height: 3px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}

body * {
  -ms-overflow-style: thin;
  scrollbar-width: thin;
}

#basic-menu .MuiMenu-paper {
  border-radius: 15px;
  margin-top: 18px;
  border: 2px solid #ddd;
  right: auto;
  overflow: visible;
  position: absolute;
  left: calc(100% - 300px) !important;
  width: 250px;
  /* top: 210px !important; */
}

#basic-menu {
  position: absolute !important;
  max-width: 767px;
  margin: auto;
}
#basic-menu .MuiMenu-list {
  padding-right: 15px !important;
  overflow: auto;
  max-height: 53vh;
}
.add-items_sec .item-details .message_col {
  padding: 0 20px 20px;
  background-color: #fff;
  margin: 0;
}

.green-border-btn {
  background: transparent;
  border: 2px solid #1ebdc3;
  color: #1ebdc3;
  height: auto;
  font-size: 20px;
  font-weight: 700;
  border-radius: 7px;
  padding: 10px 0 !important;
}

.bottom-sec.flex_btns .btns.duo {
  padding: 0 10px;
}
.bottom-sec.flex_btns .btns {
  display: flex;
  align-items: center;
}
.bottom-sec.flex_btns .adding-items,
.bottom-sec.flex_btns .btns {
  max-width: 100%;
  flex: 0 0 100%;
}

.bottom-sec.flex_btns .btns .add_btn {
  max-width: calc(100% - 120px);
}

.bottom-sec.flex_btns .btns .back_btn {
  width: 100%;
  margin: 15px auto 0;
  max-width: 100px;
  font-size: 20px;
}
.add-items_sec + .bottom-sec .btns.duo .green-btn {
  margin: 15px auto 0;
}

.send-btn {
  width: 55px;
  flex: 0 0 55px;
  padding: 0 6px 0px 0;
  text-align: right;
}

.chat-container.Help .title,
.user-section.Help .title {
  font-size: 28px;
}
.timeColor,
.labelColor,
.css-1cfef1q .labelColor > .MuiStepLabel-labelContainer .MuiStepLabel-label {
  color: rgb(194, 15, 111) !important;
}

.chat-container.Help {
  height: 74vh;
}
.bckLive_Updates .MuiList-root {
  padding: 0;
}

.terms-modal {
  display: flex;
  flex-wrap: wrap;
  margin: 3px;
  padding: 20px 15px;
}

.OtpVerifyMs .Payment .group.cardDetails-sec label.mb-8 {
  margin-bottom: 100px !important;
}

/* NEW CSS */


html {
  background: #f5f5f5;
}

body.white.laptop-device{
  background: #f5f5f5 !important;
  max-width: 100%;
}

body.laptop-device .small-width-wrapper{
  max-width: 767px;
  margin: auto;
}

.laptop-device .circle-img .logo{
  border: 1px solid #aaa9a9;
}

.laptop-device .circle-img .logo img{
  border: 1px solid #575554;
  padding: 4px;
  border-radius: 50%;
}

.laptop-device .powered_text{
  display: none !important;
}

.laptop-device .naming-sec p{
  text-transform: uppercase;
}

.laptop-device .circle-img figure{
  border: 1px solid #aaa9a9;
  width: 85px;
  height: 85px;
}

.laptop-device .circle-img figure img{
  border: 1px solid #575554;
  padding: 4px;
  border-radius: 50%;
}

.laptop-device .lmr-65{
  margin-left: 45px;
}

.laptop-device .wrapper{
  max-width: 100%;
  background: #f5f5f5;
}

.laptop-device .overflow-auto::-webkit-scrollbar{
  display: none;
}

.laptop-device .link-box{
  padding: 0 5px 0px;
}

.laptop-device .mt-new{
  margin-top: 20px;
}

.laptop-device .link-box a{
  width: fit-content;
  height: 45px !important;
  padding: 0px 12px;
  white-space: nowrap;
}

.laptop-device .link-box.active:after{
  display: none;
}

.laptop-device .container:not(.is-max-desktop):not(.is-max-widescreen){
  max-width: 100%;
}

.laptop-device .search-bar.input-text{
  width: 30%;
  margin: 8px 0px 15px 0px;
}

.laptop-device header .container .row.mx-0{
  justify-content: flex-end;
}

/*.laptop-device .middle-sec{
  display: flex;
  flex-wrap: wrap;
}
.laptop-device .middle-sec .menu-sections{
  width: 70%;
}
.laptop-device .middle-sec .width-30{
  width: 30%;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}*/

.laptop-device .middle-sec .width-30 .white-bg{
  background: white;
  /* padding-left: 15px;
  padding-right: 15px; */
}

.laptop-device .outerScroll .title{
  background-color: white;
}

.laptop-device .billing-details{
  padding-bottom: 50px !important;
}

.laptop-device .orderSummary{
  max-height: calc(100vh - 340px);
}

.laptop-device .cart-sec-ms .menu-box{
  padding: 0px;
}

.laptop-device .menu-box{
  background-color: #f5f5f5;
  padding-top: 7px;
  padding-bottom: 7px;
}

.laptop-device .menu-sections .title{
  padding: 10px 20px 16px 20px;
}

.laptop-device .main-box-new{
  /* padding: 7px 26px 7px; */
  background: white;
}

.laptop-device .menu-sections{
  background: #ffffff;
  max-height: inherit;
}

.laptop-device .width-30 .menu-sections{
  background: white;
}

.laptop-device .outerScroll .menu-box{
  background-color: #ffffff;
}

.laptop-device .menu-sections .infinite-scroll-component{
  overflow-x: hidden !important;
}

.laptop-device .custom_pb .bottom-sec{
  position: relative;
}

/*.laptop-device .new-delivery-btn .bottom-sec{
  position: relative;
}
*/
.laptop-device .menu-box .row{
  background: white;
  padding: 20px 25px;
  margin-bottom: 10px;
  min-height: 135px;
  border-radius: 8px;
  border: 1px solid red;
}

.laptop-device .orderMenu{
  padding: 0px 15px 20px 15px;
}

.laptop-device .side-bar{
  position: absolute;
  width: 10px;
  height: calc(100% - 60px);
  top: 24px;
  left: 15px;
  z-index: 1;
  background-color: #d51b67;
}

.laptop-device .middle-sec .width-30 .white-bg .wrapper{
  background: white;
}

body.laptop-device{
  position: inherit !important;
}

.laptop-device .custom_pb .bottom-sec{
  background: #f5f5f5;
  border: none;
}

.laptop-device #basic-menu{
  max-width: 100%;
}

.laptop-device #basic-button{
  position: relative !important;
}

.laptop-device #basic-menu .MuiMenu-paper{
  left: calc(100% - 290px) !important;
}

.laptop-device .wrapper.cart-screen{
  padding: 0px;
}

.laptop-device .filter-class{
  max-width: 5%;
  display: flex;
  align-items: center;
}

.laptop-device .filter-class-f{
  max-width: 5%;
  display: flex;
  align-items: center;
}

.laptop-device .wrapper .bottom-sec{
  max-width: 500px !important;
}

.laptop-device #new-MaxBtn{
  max-width: 500px !important;
  background-color: #f5f5f5;
}

.laptop-device #cart-maxbtn{
  max-width: 767px !important;
}

.laptop-device .custom_pb{
  height: inherit;
}

.laptop-device .menu_sec{
  margin-bottom: 0px;
  justify-content: end;
}

.laptop-device .is-sticky-header{
  position: fixed;
  top: 0px;
  z-index: 999;
  background: #f5f5f5;
  width: 100%;
  left: 0;
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.laptop-device .is-sticky-header .menu-row{
  padding: 0 35px;
}

.laptop-device .is-sticky-cart{
  position: fixed;
  top: 0px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  background: white;
  margin-top: 80px;
  width: 22%;
}

.laptop-device .new-wrapper{
  margin: initial;
  padding: 0px;
}

/* Modal Design */

.laptop-device .new-wrapper .bottom-sec .adding-items{
  padding: 5px 20px;
}

.laptop-device .add-items_sec .item-details .selectors{
  padding-bottom: 30px !important;
}

.laptop-device .add-items_sec .item-details{
  height: calc(100vh - 525px);
}

.laptop-device .add-items_sec .item-details.top-scroll{
  height: calc(100vh - 315px);
}

.laptop-device .add-items_sec .item-details.top-scroll{
  margin-top: -40px;
}

.laptop-device .add-items_sec .item-details .product-details .price{
  display: none;
}

.laptop-device .add-items_sec .item-details .product-details .description{
  margin-top: 20px;
}

.laptop-device
  .add-items_sec
  .item-details.top-scroll
  .product-details
  .description {
  margin-top: 20px;
}

/*.laptop-device .menu-sections .menu-box{
  padding-left: 0px !important;
  padding-right: 0px !important;
}*/

.laptop-device
  .step-master
  .MuiStep-vertical:nth-child(3)
  .css-vnkopk-MuiStepLabel-iconContainer
  figure:after{
  display: none;
}

.laptop-device .max-wrapper-width{
  max-width: 767px;
}

.laptop-device .max-wrapper-width .bottom-sec{
  max-width: 767px !important;
}

.laptop-device .other-options{
  padding: 0px;
  font-size: 13px;
}

.laptop-device .new-delivery-margin{
  margin: 0px !important;
}

.laptop-device .height-manage{
  height: inherit;
}

.sticky{
  position: sticky;
  top: 65px;
}

.laptop-device .sticky-head{
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #f5f5f5;
  width: 100%;
  left: 0;
  padding: 10px 0px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;*/
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.overflow-visible{
  overflow: visible !important;
}

.laptop-device .OtpVerifyMs .Payment .group.cardDetails-sec label.mb-new-8{
  margin-bottom: 1.5rem !important;
}

.laptop-device .hotel_name .naming-sec{
  margin-left: 65px;
}

.laptop-device .hotel_name.con_back .naming-sec{
  margin-left: 0px;
}

.laptop-device .confirm-width .hotel_name.con_back .naming-sec{
  margin-left: 65px;
}

.laptop-device .new-bcrumb{
  background: none !important;
}

.laptop-device .new-bcrumb li{
  padding-right: 13px;
  font-weight: bold;
  color: #000;
}

.laptop-device .new-bcrumb li + li::before{
  color: #000;
  content: "\02758";
  padding-right: 10px;
}

.laptop-device .new-bcrumb .before-border::before{
  color: #000;
  content: "\02758";
  padding-right: 10px;
}

/* Breadcrumb */
.breadcrumb-item + .breadcrumb-item::before{
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
/* End Breadcrumb */

.laptop-device .change-header{
  color: #39b5b4;
  padding: 12px 0px 12px 16px;
  font-weight: bold;
}

.laptop-device .change-header:hover{
  text-decoration: none;
}

@media (min-width: 1300px){
  .side-card-width{
    max-width: 2.55555% !important;
  }
}

@media (min-width: 1201px) and (max-width: 1290px){
  .side-card-width{
    max-width: 5.333333% !important;
  }
}

.laptop-device .new-add-remove{
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  border: 1.5px solid #bababa;
  background: #fff !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop-device .new-add-remove svg{
  width: 14px;
  padding: 1.2px;
}

.laptop-device .new-add-add{
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
  border: 1.5px solid #bababa;
  background: #fff !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop-device .new-add-add svg{
  width: 14px;
   padding: 1.2px;
}

.laptop-device .new-items-num{
  width: 30px !important;
  font-size: 19px !important;
}

.laptop-device .items-sec-end{
  justify-content: end;
}

.laptop-device .cart-sec-ms .menu-content h4{
  font-size: 15px;
}

.laptop-device .filterDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  height: 21px;
  width: 21px;
  position: absolute;
  right: -9px;
  top: 13px;
}

.laptop-device .filterSpan{
  font-weight: 900;
  color: white;
  font-size: 14px;
}

.laptop-device .menu-sections .menu-box:after{
  content: "";
  /* border-bottom: 1px solid #e8e8e8; */
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #ffffff !important;
}

.laptop-device .menu-sections .menu-box:after{
  content: "";
  /* border-bottom: 1px solid #e8e8e8; */
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #ffffff !important;
}

.laptop-device .mt-new .menu-sections .title{
  background: white;
}

.laptop-device .width-30 .white-bg .bottom-sec .sub-btn{
  position: fixed;
  bottom: 10px;
  width: 22.4%;
}

/*.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
  position: relative;
  width: 100%;
}*/

/* new changes delivery new-btn */

.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
  position: fixed;
  bottom: 65px;
  width: 22.4%;
}

.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec{
  left: inherit;
  right: inherit;
}

.laptop-device .new-delivery-btn .bottom-sec{
  position: fixed;
  width: 22.4% !important;
}





.laptop-device .plr-15{
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.laptop-device .promotion_section .menu-box{
  padding: 0px !important;
}

.laptop-device .menu-sections .title.promotions{
  padding: 30px 20px 10px 0px;
}

.laptop-device .promotion-heading{
  padding-left: 5px;
}

.laptop-device .empty-basket-card{
  box-shadow: none !important;
  border: none !important;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px !important;
}

.laptop-device .empty-basket-card h3{
  color: #c7c7c7;
  font-weight: 900 !important;
}

.laptop-device .bcrum{
  background: none;
  float: right;
}

.laptop-device .bcrum li{
  font-weight: bold;
  color: #000;
}

.laptop-device .bcrum li + li::before{
  color: #000;
  content: "\02758";
}

.laptop-device .bcrum .Delivery-icon::before{
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px){
  .laptop-device .pl-m-0{
    padding-left: 0px;
  }
}

.laptop-device .change-yellow{
  color: #39b5b4;
  font-weight: bold;
}
.laptop-device .change-yellow:hover{
  text-decoration: none;
}

.laptop-device .cartDiv{
  right: 23px;
  top: -15px;
}

.menu-sections .plr-150 .menu-box:after{
  display: none !important;
  background: none;
}

@media (min-width: 769px){
  .position-relative-m-0{
    position: relative;
  }
}

.laptop-device .confirm-width{
  max-width: 767px;
}

.laptop-device .manage-wh figure.menu-img{
  min-width: 70px;
}

.laptop-device .increase-height{
  max-height: calc(100vh - 146px) !important;
}


/* mobile promotion border bottom */

.plr-150 .main-box-new.menu-box.br-1:after {
    content: "";
    width: calc(100% - 32px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block!important;
    height: 1px !important;
    background:#e8e8e8 !important;
  }

@media (min-width:769px){
    .plr-150 .main-box-new.menu-box.br-1:after {
    display: none !important;
  }
}  


/* 28 feb */

  .laptop-device .height-manage .Payment .group label{
    align-items: baseline;
  }
@media (min-width: 800px) and (max-width:899px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 }
 .laptop-device .countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    margin-left: -10px !important
} 
}

@media (min-width: 769px) and (max-width:1023px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 60vh !important;
 } 
}

@media (min-width:800px) and (max-width:1000px){
  .laptop-device .addressModelCon .MuiPaper-root{
    height: 74vh !important;
  }
}


@media (min-width:820px) and (max-width:1000px){
  .laptop-device .addressModelCon .MuiPaper-root{
    height: 60vh !important;
  }
}

@media (min-width: 900px) and (max-width:1000px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 } 
}

@media (min-width:910px) and (max-width:1000px){
  .laptop-device .addressModelCon .MuiPaper-root{
    height: 50vh !important;
  }
}


@media (min-width: 913px) and (max-width:1000px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 } 
}



@media (min-width: 1024px) and (max-height: 1366px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 50vh !important;
 } 
}

@media (min-width: 1024px) and (max-height: 768px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 } 
}


@media (min-width: 1050px) and (max-height: 1366px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 } 
}


@media (min-width: 1200px) and (max-height:1368px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 84vh !important;
 } 
}

@media (min-width: 1250px) and (max-height:1368px){
 .laptop-device .addressModelCon .MuiPaper-root{
  height: 74vh !important;
 } 
}

@media (min-width: 1300px) and (max-height: 1024px){
.laptop-device .addressModelCon .MuiPaper-root {
    height: 65vh !important;
}
}

@media (min-width: 1400px){
.laptop-device .addressModelCon .MuiPaper-root {
    height: 74vh !important;
}
}

.p-padding{
  padding: 0px 20px;
}
.p-padding p{
  font-size: 15px;
}
.p-padding .progress{
  height: 12px;
  margin-bottom:15px;
}

.progress-bar {
  animation: progress 1500ms ease-in 1;
}

@keyframes progress {
  from {
      width: 0;
  }
}


/* new */

.middle-sec .menu-row .promo-text a{
  font-size: 11px;
}

.new-menu-sec .title{
  padding: 25px 15px 10px;
}

.new-menu-sec .mb-px-0{
 padding-left: 0px;
 padding-top: 0px;
 padding-right: 0px;
 padding-bottom:0px;
}

.new-menu-sec .title{
  font-size: 18px;
}

.new-menu-sec .mb-px-0::after{
  display: none !important;
}

.new-menu-sec .menu-content p.pricing.strike-off {
    font-size: 16px;
    text-decoration: line-through;
}

.new-menu-sec .menu-content p.promotion-price {
    font-size: 18px;
}

.new-menu-sec .menu-content {
    padding-bottom: 5px;
    position: static;
}

